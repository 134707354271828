import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "state", "unsent", "count"];
  static values = {
    required: Array
  }

  visibleOtherCategory() {
    const otherTextChecked = document.querySelector('#form_直近の職種カテゴリ_その他__その他:checked');
    console.log(otherTextChecked);
    if (otherTextChecked) {
      document.querySelector('#form-TalentRecentJobFreeText__c').style.display = 'block';
    } else {
      document.querySelector('#form-TalentRecentJobFreeText__c').style.display = 'none';
    }
  }

  visibleReview() {
    const checked = document.querySelector('#form-ninchikeiro__c input:checked');
    if (checked && checked.value == '知人の口コミ・紹介') {
      document.querySelector('#form-introducer__c').style.display = 'block';
    } else {
      document.querySelector('#form-introducer__c').style.display = 'none';
    }
  }

  connect() {
    this.visibleReview()
    this.visibleOtherCategory()
    this.localStorageKey = window.location;
    this.renderCount()
  }

  count() {
    const payload = new FormData(this.formTarget);
    const checked = {}

    for(let input of payload) {
      const name = input[0];
      const required = this.requiredValue.includes(name);
      if (!required) continue;

      const value = input[1].length > 0;
      if (value) {
        checked[name] = true
      }
      // console.log(`${name} = ${value}`)
    }
    return Object.keys(checked).length;
  }

  renderCount() {
    this.countTarget.innerHTML = `必須：${this.requiredValue.length} / 入力済み：${this.count()}`
  }

  perist(e) {
    this.renderCount();

    // show auth-save
    const section = e.target.closest('.input');
    this.stateTarget.dataset.state = 'saving';
    this.stateTarget.style.top = `${section.offsetTop}px`;

    const payload = new FormData(this.formTarget);
    // perist data
    const token = document.getElementsByName('csrf-token')[0].content
    const headers = {
      'X-CSRF-Token': token,
      'Accept': 'application/json',
    }
    fetch(location.href, {
      method: 'PATCH',
      headers: headers,
      body: payload,
    })
    .then(response => {
      this.stateTarget.dataset.state = 'saved';
      this.unsentTarget.dataset.state = 'true';
    });
  }

  onChange(event) {
    if (event.currentTarget.id == "form_直近の職種カテゴリ_その他__その他") {
      this.visibleOtherCategory()
    }
    if (event.currentTarget.name == 'form[withworkをどこで知りましたか？]') {
      this.visibleReview()
    }
  }
}
